export default [
  // {
  //   title: 'About',
  //   route: 'home',
  //   icon: 'TvIcon',
  // },
  {
    title: 'Clips',
    route: 'clips',
    icon: 'ScissorsIcon',
  },
  {
    title: 'Past Streams',
    route: 'vods',
    icon: 'FilmIcon',
  },
]
